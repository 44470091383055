// Feature flag names are defined here. Each enum value is a feature flag name.
export enum FeatureFlag {
    USERS_AND_LOCATIONS_USERS_TAB = 'USERS_AND_LOCATIONS_USERS_TAB',
    USERS_AND_LOCATIONS_LOCATIONS_TAB = 'USERS_AND_LOCATIONS_LOCATIONS_TAB',
    USERS_AND_LOCATIONS_COMMON_BANNER_LOCALE_KEY = 'USERS_AND_LOCATIONS_COMMON_BANNER_LOCALE_KEY',
}

type FeatureFlagConfigMap = {
    [key in FeatureFlag]: {
        type: 'boolean' | 'string'
        variants: string[]
        defaultVariant: string
        configKey: Uppercase<`FF_${key}`>
        k8sConfigKey: Uppercase<`NUXT_PUBLIC_FF_${key}`>;
    };
}

// Feature flag config map is defined here.
// Each feature flag has a type, variants, default variant and config key.
// The config key is used to get the feature flag value from the runtime config.
// configKey name is automatically generated from the feature flag name.
// For example, for feature flag USERS_AND_LOCATIONS_USERS_TAB, the config key is FF_USERS_AND_LOCATIONS_USERS_TAB.
// The k8sConfigKey is how the feature flag is defined in the k8s config map.
// For example, for feature flag USERS_AND_LOCATIONS_USERS_TAB, the k8sConfigKey is NUXT_PUBLIC_FF_USERS_AND_LOCATIONS_USERS_TAB.
export const featureFlagConfigMap: FeatureFlagConfigMap = {
    [FeatureFlag.USERS_AND_LOCATIONS_USERS_TAB]: {
        type: 'boolean',
        variants: ['on', 'off'],
        defaultVariant: 'off',
        configKey: 'FF_USERS_AND_LOCATIONS_USERS_TAB',
        k8sConfigKey: 'NUXT_PUBLIC_FF_USERS_AND_LOCATIONS_USERS_TAB',
    },
    [FeatureFlag.USERS_AND_LOCATIONS_LOCATIONS_TAB]: {
        type: 'boolean',
        variants: ['on', 'off'],
        defaultVariant: 'off',
        configKey: 'FF_USERS_AND_LOCATIONS_LOCATIONS_TAB',
        k8sConfigKey: 'NUXT_PUBLIC_FF_USERS_AND_LOCATIONS_LOCATIONS_TAB',
    },
    [FeatureFlag.USERS_AND_LOCATIONS_COMMON_BANNER_LOCALE_KEY]: {
        type: 'string',
        variants: ['users_and_locations.alert.message', 'users_and_locations.alert.not_ready_message'],
        defaultVariant: 'users_and_locations.alert.not_ready_message',
        configKey: 'FF_USERS_AND_LOCATIONS_COMMON_BANNER_LOCALE_KEY',
        k8sConfigKey: 'NUXT_PUBLIC_FF_USERS_AND_LOCATIONS_COMMON_BANNER_LOCALE_KEY',
    },
}
